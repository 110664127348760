@import '@/styles/global_vars.scss';
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';






























































































































































#gelest-publications {
  #main-app-bar {
    > .v-toolbar__content {
      padding: 0 !important;
    }
  }

  .v-app-bar > .v-toolbar__extension {
    flex-direction: column;
    padding: 0;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0 !important;
}

.v-sheet {
  z-index:10;
}

