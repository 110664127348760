@function ___loading() {

  $path_as_string: str-quote(&);

  @if str-index($path_as_string, '#app') {
    @return selector-replace(&, '#app', '#app.publications-is-loading');
  }
  @else if str-index($path_as_string, '.v-application') {
    @return selector-replace(&, '.v-application', '.v-application.publications-is-loading');
  }
  @else if str-index($path_as_string, '#gelest-publications') {
    @return selector-replace(&, '#gelest-publications', '#gelest-publications .publications-is-loading');
  }
  @else {
    @if & {
      @return selector-nest('#gelest-publications', '.publications-is-loading', &);
    }
    @else {
      @return '#gelest-publications .publications-is-loading';
    }
  }
}

@mixin whenLoading {
  @at-root #{___loading()} {
    @content;
  }
}


/*
A simple little SCSS mixin for creating scrim gradients
Inspired by Andreas Larson - https://github.com/larsenwork
https://css-tricks.com/easing-linear-gradients/
https://codepen.io/joeyquarters/pen/dWJYdV
*/
@mixin scrim-gradient($direction: 'to bottom', $startColor: 'black') {

  $scrimCoordinates: (
    0: 1,
    19: 0.738,
    34: 0.541,
    47: 0.382,
    56.5: 0.278,
    65: 0.194,
    73: 0.126,
    80.2: 0.075,
    86.1: 0.042,
    91: 0.021,
    95.2: 0.008,
    98.2: 0.002,
    100: 0
  );

  $hue: hue($startColor);
  $saturation: saturation($startColor);
  $lightness: lightness($startColor);
  $stops: ();

  @each $colorStop, $alphaValue in $scrimCoordinates {
    $stop: hsla($hue, $saturation, $lightness, $alphaValue) percentage($colorStop/100);
    $stops: append($stops, $stop, comma);
  }

  background: linear-gradient(unquote($direction), $stops);

}


// @mixin v-slide-group-fade($width-of-prev-next-btn, $size-of-gradient-fade: 30px) {

//   .v-slide-group__prev:after,
//   .v-slide-group__next:before {
//     content: '';
//     display: block;
//     position: absolute;
//     min-width: $size-of-gradient-fade;
//     height: 100%;
//     z-index: 2;
//   }
//   .v-slide-group__prev:after {
//     left: $width-of-prev-next-btn;
//     @include scrim-gradient('90deg', white);
//   }
//   .v-slide-group__next:before {
//     right: $width-of-prev-next-btn;
//     @include scrim-gradient('270deg', white);
//   }
// }
