@import '@/styles/global_vars.scss';
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';





















































































































.TIF-min-max-range-filter {
  .v-messages__message {
    text-align: center;
    font-size: 14px;
    color: $offblack;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .prepend-input, .append-input {
    width: 56px;
    height: 30px;

    .v-input__control {
      .v-input__slot {
        .v-text-field__slot {
          input {
            text-align: center;
            font-size: 14px;
            color: black;
            background-color: white;
            border-radius: 3px;
            border: 1px solid $gray;
          }
        }
      }
    }
  }

 
  .v-input__prepend-outer,
  .v-input__append-outer {
    align-self: flex-end;
  }

  .v-text-field > .v-input__control > .v-input__slot:before,
  .v-text-field > .v-input__control > .v-input__slot:after {
    border-color: rgba(0, 0, 0, 0);
  }
  .v-text-field:not(.v-input--has-state) > .v-input__control > .v-input__slot:before {
    border-color: rgba(0, 0, 0, 0);
  }
  .v-text-field:not(.v-input--has-state):hover > .v-input__control > .v-input__slot:before {
    border-color: rgba(0, 0, 0, 0.87);
  }

  .v-input__control {
    margin-top: 8px;
  }

  .v-input__prepend-outer .v-text-field__slot > input {
    text-align: right;
  }

  .filter-slider {
    margin-bottom:22px;
    .v-input__control {
      .v-input__slot {
        padding: 5px 0;
        margin-bottom: 24px;
        .v-slider {
          margin: 0 -45px;
        }
      }
    }
  }
}
