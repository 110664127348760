.hover-pointer {
  &:hover {
    cursor: pointer !important;
  }
}

.disabled-pointer{
  &:hover {
    cursor: not-allowed !important;
  }
}

.default-cursor {
  cursor: default;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.theme--light.v-skeleton-loader {

  .v-skeleton-loader__actions,
  .v-skeleton-loader__article,
  .v-skeleton-loader__card-heading,
  .v-skeleton-loader__card-text,
  .v-skeleton-loader__date-picker,
  .v-skeleton-loader__list-item,
  .v-skeleton-loader__list-item-avatar,
  .v-skeleton-loader__list-item-text,
  .v-skeleton-loader__list-item-two-line,
  .v-skeleton-loader__list-item-avatar-two-line,
  .v-skeleton-loader__list-item-three-line,
  .v-skeleton-loader__list-item-avatar-three-line,
  .v-skeleton-loader__table-heading,
  .v-skeleton-loader__table-thead,
  .v-skeleton-loader__table-tbody,
  .v-skeleton-loader__table-tfoot,
  .v-skeleton-loader__spacer {
    background-color: $lightgray !important;
  }

  .v-skeleton-loader__spacer {
    height: 20px;
  }
}

.rotate-90 {
  transform: rotate(90deg);
}

.TIF-link-button {
  &:hover, &:focus {
    &::before {
      opacity: 0.2 !important;
    }
    > span {
      color: $primary;
    }
  }

  span span {
    transition: color 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  }

  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: .25px;
    text-transform: none !important;
  }
}

#gelest-publications .TIF-link {
  text-transform: none !important;

  &, i, span {
    color: $midgray;
    transition: color 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  }

  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: .25px;
  }

  &:hover, &:focus {
    i { color: $primary; }

    > span {
      color: $primary;
    }
  }
}

.TIF-checkbox {
  min-width: 100%;
  white-space: initial;

  .v-input--selection-controls__input {
    align-self: start;
  }

  label.v-label {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  &.v-input--selection-controls .v-input--selection-controls__input:hover .v-input--selection-controls__ripple:before {
      opacity: 0.1;
      transform: scale(1);
      width: 23px;
      height: 23px;
      top: 2.5px;
      left: 2.5px;
  }
  span.v-ripple__container {
    transform: scale(1);
    width: 23px;
    height: 23px;
    top: 2.5px;
    left: 2.5px;
  }

  .info-button {
    margin-top: 2px;
  }
}

.TIF-tooltip,
.guide-button-tooltip {
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  padding: 16px 4px;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-sides-0 {
  border-left: 0 !important;
  border-right: 0 !important;
}
