@import '@/styles/global_vars.scss';
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';


















































































































































































































































































#gelest-publications {
  .drawer-search-input {
    .v-input__control {
      .v-input__slot {
        padding: 0 20px;
        fieldset {
          border-color: $midgray !important;
          border-width: 1px;
        }
      }
    }
  }

  .v-input__append-inner {
    margin-top: 5px;
    .v-input__icon {
      .mdi-close {
        margin-top: 8px;
        font-size: 18px;
        color: $midgray !important;
      }
    }
    span {
      margin-top: 9px;
      svg {
        height: 13px;
        width: 13px;
      }
    }
  }

  .checkbox-list-item {
    min-height: 30px;
    &:last-child {
      padding-bottom: 12px;
    }

    .v-list-item__content {
      padding-top: 0;
      padding-bottom: 0;
    }
  }


  .force-to-top {
    order: 0 !important;
  }

  .item-group-grid {
    display: grid;
    grid-template-areas: "search" "checkboxes" "viewmore"
  }

  .grid-is-collapsed {
    overflow-y: hidden;
    grid-auto-rows: 0;
  }

  .view-more-less-button {
    text-transform: none;
    letter-spacing: normal;
    color: $primary;
    width: 108px;
    margin-top: 10px;
  }
}
