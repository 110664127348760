@import '@/styles/global_vars.scss';
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';




























































#gelest-publications {

  #header-title-container {
    .header-title {
      text-overflow: ellipsis;
      font-size: 32px;
      overflow: hidden;
      white-space: nowrap;
      max-width: 100%;
      font-weight: 300;
      vertical-align: text-bottom;
      @media (min-width: $breakpoint-sm) {
        vertical-align: bottom;
      }
    }
  }

  .toolbar-grid {
    display: grid;
    grid-template-areas: 'title';
    grid-template-columns: 1fr;

    @media (min-width: $breakpoint-sm) {
      grid-template-areas: 'title dropdown closebtn';
      grid-template-columns: minmax(0, 1fr) 200px 48px;
      grid-column-gap: 20px;
    }

    #header-title-container {
      grid-area: title;
    }
    #customize-column-dropdown {
      grid-area: dropdown;
    }
    #header-close-button {
      grid-area: closebtn;
    }
  }
}

// @mixin like-a-container() {
//     width: 100%;
//     padding: 12px;
//     margin-right: auto;
//     margin-left: auto;

//     @media (min-width: 960px)
//     {
//         max-width: 900px;
//     }

//     @media (min-width: 1264px)
//     {
//         max-width: 1185px;
//     }
// }

#guide-header-toolbar > .v-toolbar__content {
  // @include like-a-container();
  padding: 0 24px;
  // @media (min-width: $breakpoint-sm) {
  //  padding: 0 42px;
  // }
}

