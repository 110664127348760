@import '@/styles/global_vars.scss';
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';



























































































































































































.search-container-main {
  background-color: #f7f8f9;

  .container {
    padding: 27px 60px;
    .v-autocomplete {
      .v-input__control {
        .v-input__slot {
          padding-left: 33px;
          height: 64px;
          border: 1px solid $gray;
          &:hover {
            background-color: #fff;
          }
          .v-select__slot {
            label,
            input {
              font-size: 24px;
              font-weight: 400;
              font-family: "Roboto", sans-serif;
            }
            label {
              color: #98a9b3;
              padding-left: 20px;
            }
            input {
              text-indent: 20px;
            }
          }
        }
      }
    }
  }
  .search-placeholder {
    .label {
      padding-left: 20px;
    }
  }
  .v-input .v-label {
    min-height: 28px;
  }
}
.v-autocomplete__content {
  top: 175px !important;
  box-shadow: 4px 10px 15px rgb(0 0 0 / 10%), -1px 5px 20px rgb(0 0 0 / 10%);
  height: auto;
  max-height: 640px !important;
  hr {
    margin: 20px 0 15px;
  }
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: $midgray !important;
  }
  .v-list {
    padding: 0;
    .v-list-item--link {
      &:last-of-type {
        padding-bottom: 40px;
      }
    }
  }
  .no-data-text,
  .prepend-search-input {
    color: $midgray !important;
    padding: 13px 0 8px 80px;
    text-indent: 11px;
    font-size: 14px;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
  }

  .prepend-search-input {
    border-bottom: 1px solid $gray;
    border-top: 1px solid $gray;
    font-weight: 400;
    .v-icon {
      svg {
        height: 12px;
        width: 12px;
      }
    }
  }
  .v-subheader {
    color: $primary;
    text-indent: 64px;
    font-size: 16px;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
  }
  .v-list-item {
    min-height: 20px;
    .v-list-item__content {
      padding: 6px 0;
      .v-list-item__title {
        text-indent: 64px;
        font-size: 14px;
        font-weight: 400;
        font-family: "Roboto", sans-serif;
        a {
          color: $midgray;
          text-decoration: none;
          .external-link {
            color: $midgray;
          }
        }
      }
    }
    &:hover {
      .v-list-item__title {
        a {
          color: $offblack;
          .external-link {
            color: $offblack;
          }
        }
      }
    }
  }
}
