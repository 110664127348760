@import '@/styles/global_vars.scss';
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';






































































































































#gelest-publications {

  // General
  .customize-column-dropdown {

    .v-expansion-panel {
      border: 1px solid $primary;
      border-radius: 6px;

      &:before {
        content: unset;
      }

      .v-expansion-panel-header {
        padding: 0 8px 0 16px;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
      }

      &.v-expansion-panel--active {
        .v-expansion-panel-header {
          border-bottom: 1px solid $gray;
        }
      }

      .v-expansion-panel-content__wrap {
        padding: {
          left: 0;
          right: 0;
        }

        @include whenLoading {
          padding-bottom: 0;
        }
      }
    }

    .v-skeleton-loader--is-loading {
      padding: 16px;
      background-color: $lightgray;

      .v-skeleton-loader__list-item-avatar {
        transform: scale(0.5) translate(-50%, -50%);
        margin-bottom: -24px;
        width: 200%;
      }
    }
  }

  // Mobile specific
  .customize-column-dropdown.mobile-positioning {
    position: fixed;
    bottom: 0;
    z-index: 10;

    .v-expansion-panel {
      width: calc(100% - 48px);
      margin: 24px;
      position: absolute;
      bottom: 0;
      left: 0;
      min-height: 62px;
      max-height: calc(100vh - 48px);
      box-shadow: rgba(0,0,0,0.1) 0 0 20px 0;

      .v-expansion-panel-header {
        min-height: 60px;
        width: calc(100% - 40px);
        margin: 0 20px;
        padding-left: calc(50% - 125px);
        padding-right: calc(50% - 125px);
        font-size: 21px;
        line-height: 18px;
        font-weight: bold;

        &.v-expansion-panel-header--active {
          min-height: 69px;
        }
      }

      .v-expansion-panel-header__icon {
        position: relative;
        left: 20px;
        transform: rotate(180deg);
      }

      .v-expansion-panel-content {
        max-height: calc(100vh - 48px - 71px);
        overflow-y: scroll;
        border-radius: 4px;

        &.scroll-stuck {
          // This doesn't work on Safari (mobile or desktop) or IE
          overscroll-behavior: contain;
        }
      }
    }
  }

  // Desktop specific
  .customize-column-dropdown:not(.mobile-positioning) {
    width: 200px;
    position: absolute;
    top: $INDIV_GUIDE_HEADER_HEIGHT__DESKTOP / 2;

    $header-height: 34px;

    .v-expansion-panel {
      width: 206px;
      position: relative;
      top: -18px;

      .v-expansion-panel-header {
        padding: 0 8px 0 16px;
        min-height: $header-height;
        min-width: 100%;
      }

      .v-expansion-panel-content {
        max-height: calc(100vh - 250px - #{$header-height});
        overflow-y: scroll;
        border-radius: 4px;
      }
    }
    &.header-collapsed {
      .v-expansion-panel {
        .v-expansion-panel-content {
          max-height: calc(100vh - 50px - #{$header-height});
        }
      }
    }
  }
}
