  @import 'mixins';

  /**
   * COLORS
   */
  $primary: #24439C;
  $secondary: #00AB79;

  $bggray: #F1F4F6;
  $lightgray: #F7F8F9;
  $gray: #D8DFE3;
  $midgray: #98A9B3;
  $offblack: #383C3A;


  /**
   * BREAKPOINTS
   *
   * These are the defaults that come with Vuetify
   */
  $breakpoint-xs: 0;
  $breakpoint-sm: 600px;
  $breakpoint-md: 960px;
  $breakpoint-lg: 1280px - 16px;
  $breakpoint-xl: 1920px - 16px;

  /**
   * APP BAR / HEADER HEIGHTS
   *
   * These need to be kept in manual sync with the JS vars in Publications.vue
   */
  $TOP_BAR_HEIGHT                    : 83px;
  $SEARCH_BAR_HEADER_HEIGHT          : 137px;
  $INDIV_GUIDE_HEADER_HEIGHT__MOBILE : 48px;
  $INDIV_GUIDE_HEADER_HEIGHT__DESKTOP: 69px;
