@import '@/styles/global_vars.scss';
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';



































































































.v-icon::after {
    opacity: 0 !important;
}
