@import '@/styles/global_vars.scss';
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';







































#back-button-mobile-toolbar > .v-toolbar__content {
  padding: 0;
}
