@import '@/styles/global_vars.scss';
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';


















































































































































































#gelest-publications {
  .search-drawer {
    flex-direction: column;
    flex: 1 0;

    .v-navigation-drawer__content {
      overflow-y: hidden;
      @media (min-width: $breakpoint-sm) {
        overflow-y: auto;
      }
    }

    .v-navigation-drawer__border {
      display: none;
      @include media-breakpoint-up(sm) {
        display: block;
      }
    }

    .v-list-item__title {
      white-space: normal;
    }

    .showFiltersTextContainer {
      position: relative;
      .showFiltersText,
      .hideFiltersText {
        transform: rotate(-90deg);
        transform-origin: 0 0;
        pointer-events: none;
        color: $midgray;
        width: 100px;
      }
      .showFiltersText {
        position: relative;
        right: -15px;
        top: 100px;
      }
      .hideFiltersText {
        position: absolute;
        left: -30px;
        top: 115px;
      }
    }
  }

  .drawer-filter-group {
    > .v-list-group__header {
      padding-right: 20px;
      @media (min-width: $breakpoint-sm) {
        padding-right: 26px;
      }
    }
  }

  .filter-popup {
    .v-card__title {
      padding: 28px 50px 18px;
    }
    .v-card__text {
      padding: 30px 50px 50px;
    }
  }

  .filter-slider {
    .v-messages__message {
      color: $offblack;
    }
  }

  .filter-divider {
    margin: 10px 16px;
  }

  .filters-list {

    @media (min-width: $breakpoint-sm) {
      margin-bottom: 0;
    }

    @media (max-width: ($breakpoint-sm - 1px)) {
      max-height: 100%;
      height: 100%;

      .skeleton-wrapper {
        max-height: calc(100% - 56px - 85px);
        height: calc(100% - 56px - 85px);
        overflow-y: scroll;
        margin-bottom: 85px;
      }
    }

  }

  .closeDrawerTapArea {
    position: absolute;
    top: 0;
    right: 0;
    width: 66px;
    height: 100%;
    z-index: 0;
  }

  .v-btn--plain .v-btn__content {
    opacity: 1 !important;
  }
}

