@import '@/styles/global_vars.scss';
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';









































































































































































































































































































#gelest-publications {

  .main-inner {
    padding-left: 15px;
    padding-right: 15px;

    padding-bottom: 85px;
    @media (min-width: $breakpoint-sm) {
      padding-bottom: 0;
    }
  }

  #pagination-arrows {
    button {
      margin: 0;
      box-shadow: none;
      border: 1px solid $primary;
      &.v-pagination__navigation--disabled{
        border: 1px solid $gray;

        i.v-icon {
          color: $midgray;
        }
      }

      &:focus {
        outline: none;
      }

      i.v-icon {
        font-size: 10px;
        color: $primary;
      }
    }

    li:first-of-type button {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    li:last-of-type button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  // the capitalized vars below are set in global_vars.scss
  $mobile-header-height:    $TOP_BAR_HEIGHT + $INDIV_GUIDE_HEADER_HEIGHT__MOBILE;
  $expanded-header-height:  $TOP_BAR_HEIGHT + $INDIV_GUIDE_HEADER_HEIGHT__DESKTOP + $SEARCH_BAR_HEADER_HEIGHT;
  $collapsed-header-height:                   $INDIV_GUIDE_HEADER_HEIGHT__DESKTOP;

  $results-bar-top-margin: 20px;
  $results-bar-height: 26px;
  $results-bar: $results-bar-top-margin + $results-bar-height;

  $table-top-margin: 5px;
  $table-bottom-margin: 8px;
  $table-margins: $table-top-margin + $table-bottom-margin;

  $to-top-link-height: 24px;
  $to-top-link-bottom-margin: 8px;
  $to-top-link: $to-top-link-height + $to-top-link-bottom-margin;

  $mobile-margin-for-FAB: 90px;

  $non-table-heights: $results-bar + $table-margins + $to-top-link;

  $sum-mobile:            $mobile-header-height    + $non-table-heights + $mobile-margin-for-FAB;
  $sum-desktop:           $expanded-header-height  + $non-table-heights;
  $sum-desktop-collapsed: $collapsed-header-height + $non-table-heights;

  #results-bar {
    margin-top: $results-bar-top-margin;
    height: $results-bar-height;
  }

  #to-top-link {
    display: inline-block;
    height: $to-top-link-height;
    margin-bottom: $to-top-link-bottom-margin;
  }

  #gelest-results-table {
    margin-top: $table-top-margin;
    margin-bottom: $table-bottom-margin;

    height: calc(100vh - #{$sum-mobile});

    @media (min-width: $breakpoint-sm) {
      height: calc(100vh - #{$sum-desktop});

      &.app-header-collapsed {
        height: calc(100vh - #{$sum-desktop-collapsed});
      }
    }

    .v-data-table__wrapper {

      overflow-x: scroll;

      // These scrollbar styles won't work for all browsers

      // /* for Firefox: */
      // scrollbar-width: auto;
      // scrollbar-color: $gray $bggray;

      // /* for Blink and WebKit */
      // &::-webkit-scrollbar {
      //   width: 18px;
      //   height: 18px;
      // }
      // &::-webkit-scrollbar-track {
      //   background: $bggray;
      //   border: 1px solid $gray;

      //   &:horizontal {
      //     border-right: 0;
      //   }
      //   &:vertical {
      //     border-bottom :0;
      //   }
      // }
      // &::-webkit-scrollbar-thumb {
      //   background-color: $gray;
      //   border-radius: 10px;
      //   border: 4.5px solid transparent;
      //   background-clip: content-box;
      //   // https://stackoverflow.com/questions/16819116/unable-to-set-the-width-of-webkit-scrollbar-thumb/16829330
      // }
      // &::-webkit-scrollbar-corner {
      //   background-color: $bggray;
      //   border: 1px solid $gray;
      // }
    }

    table {
      .table-structure-img {
        background-color: white;
        border: 1px solid $gray;
        border-radius: 3px;
      }
      .table-row > td,
      thead tr th {
        background: $lightgray;
        padding: 12px !important;
      }

      .TIF__column_header span {
        line-height: 1.4;
        padding: 5px 0;
      }

      .external-link {
        transition: color 0.2s cubic-bezier(0.4, 0, 0.6, 1);
        &:hover {
          color: var(--v-primary-lighten1);
        }
      }
    }
  }

  .TIF_image_overlay {
    caption {
      position: absolute;
      margin-top: 25px;
      color: white;
      width: calc(100vw - 48px);
      @media (min-width: $breakpoint-sm) {
        width: 500px;
      }
      word-wrap: break-word;
    }
  }

}

