@import '@/styles/global_vars.scss';
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';


































































































.TIF_clamped_text {
  width: 250px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

a {
  text-decoration: underline;
}

.external-link {
  transition: color 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  &:hover {
    color: var(--v-primary-lighten1);
  }
}

.expand-toggle {
  color: grey;
}
