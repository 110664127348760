@import '@/styles/global_vars.scss';
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';































































































































































#gelest-publications {
  .wizard-stepper {
    max-width: 950px;
    margin: 0 auto;
  }
}

